.header {
  /* background: url('/public/jpeg/wallpaper_two.png') no-repeat center center; */
  /* background: url('/public/jpeg/ARIWallpaper.jpg') no-repeat center center; */
  background-size: cover;
  height: 250px; /* Altura más pequeña */
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content {
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 10px;
}

.header-title {
  font-size: 2em; /* Tamaño de fuente ajustado */
  margin: 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alineación superior para acercar al header */
  min-height: calc(100vh - 200px); /* Ajuste dinámico de altura */
  background-color: #f0f2f5;
  padding-top: 20px; /* Espacio adicional superior */
}

.form-container {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 60%; /* Ocupa el 60% del ancho de la pantalla */
  max-width: 800px; /* Ancho máximo */
  min-width: 300px; /* Ancho mínimo */
  margin-top: -30px; /* Ajuste para acercar más el formulario al header */
}

/* Media Queries para tamaños de pantalla más pequeños */
@media (max-width: 768px) {
  .header {
    height: 150px; /* Altura más pequeña para pantallas pequeñas */
  }

  .header-title {
    font-size: 1.5em; /* Tamaño de fuente más pequeño */
  }

  .content {
    min-height: calc(100vh - 150px);
  }

  .form-container {
    width: 80%; /* Ajuste de ancho para pantallas más pequeñas */
    margin-top: -20px; /* Ajuste para acercar más el formulario al header */
  }
}

@media (max-width: 480px) {
  .header {
    height: 120px; /* Altura aún más pequeña para pantallas muy pequeñas */
  }

  .header-title {
    font-size: 1.2em; /* Tamaño de fuente más pequeño */
  }

  .content {
    min-height: calc(100vh - 120px);
  }

  .form-container {
    width: 90%; /* Ajuste de ancho para pantallas más pequeñas */
    margin-top: -15px; /* Ajuste para acercar más el formulario al header */
  }
}
